<template>
  <div class="dev-buttons">
    <div><button @click="clearData" class="dev-button">Clear Data (Dev)</button></div>
    <div><button @click="fillTestData" class="dev-button">Fill Test Data (Dev)</button></div>
    <button class="dev-button" @click="toggleSuccess">Toggle Success (Dev)</button>
  </div>
  <div class="grid-table-container">
    <div class="grid-table">
      <TopBar />
      <div class="grid-table-cell-wide column">
        <div class="container">
          <img src="../assets/follow-granny-logo2.png" alt="Follow Granny Logo" class="logo" />

          <template v-if="!showSuccess">
            <h2 class="signup-title">{{ ui_string.family_ig_title }}</h2>
            <p class="signup-step" v-html="formatUIString(ui_string.family_ig_step1, { br: '<br />' })"></p>
            <div v-if="igAuthStatus === 'failed'" class="error-message">
              {{ formatUIString(ui_string.family_ig_auth_status_failed, {
                ig_account: signupData.family_slug ||
                  ui_string.family_ig_callee_ig_backup
              }) }}
            </div>

            <button @click="handleAllowConnection" class="ig-auth-button" :disabled="signupData.ig_verified">
              {{ signupData.ig_verified ? `${signupData.family_slug} Connected ✓` : ui_string.family_ig_connect }}
            </button>

            <p class="signup-step">{{ formatUIString(ui_string.family_ig_step2, {
              ig_account: signupData.family_slug
                || ui_string.family_ig_callee_ig_backup
            }) }}</p>

            <input type="text" inputmode="numeric" :placeholder="ui_string.family_ig_callee_phone"
              v-model="signupData.callee_phone" />

            <p class="signup-step">{{ formatUIString(ui_string.family_ig_step3, {
              ig_account: signupData.family_slug ||
                ui_string.family_ig_callee_ig_backup
            }) }}</p>
            <!-- <div v-for="(_, index) in 1" :key="index">
              <input type="text" :placeholder="ui_string.family_ig_guest1" v-model="signupData.ig_caller[index]" />
            </div> -->

            <button class="login-button" @click="handleLoginToStoryCall"
              :disabled="!signupData.ig_verified || isLoading">
              <i class="fas fa-spinner fa-spin" v-if="isLoading"></i>
              {{ formatUIString(ui_string.family_ig_login, { ig_account: signupData.family_slug }) }}
            </button>
          </template>

          <template v-else>
            <div class="success-message">
              <p v-html="formatUIString(ui_string.ig_success, {
                ig_account: signupData.family_slug,
                br: '<br />'
              })"></p>

              <div>
                <p v-html="formatUIString(ui_string.family_ig_success_1, {
                    ig_account: signupData.family_slug,
                    br: '<br/>'
                })"></p>

                <div v-if="syncedCallers.created.length > 0 " class="synced-callers">
                  <p v-if="syncedCallers.created.length > 0">
                    {{ ui_string.family_ig_success_new_callers }}
                    <span class="caller-list">{{ syncedCallers.created.join(', ') }}</span>
                  </p>

                </div>

                <p class="copy-message notice" @click="copyMessageToClipboard(formatUIString(ui_string.family_ig_success_dm_invite, {
                  ig_account: signupData.family_slug,
                }))">
                  {{ formatUIString(ui_string.family_ig_success_dm_invite, {
                    ig_account: signupData.family_slug
                  }) }}
                </p>
                <p>{{ ui_string.family_ig_success_2 }}</p>

              </div>
              <button class="login-button" @click="handleSuccessDone">
                {{ ui_string.family_ig_success_done }}
              </button>

            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, onUnmounted } from 'vue';
import TopBar from './TopBar.vue';
import axios from 'axios';
import { useSettingsStore } from "../store";
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';
import { checkIgUsername } from '../utils/instagram';

const settings = useSettingsStore();
const { BACK_URL, ui_string } = storeToRefs(settings);
const { formatUIString } = settings;

// Create initial state object
const initialSignupState = {
  family_slug: '',
  family_password: '',
  ig_verified: false,
  ig_username: '',
  callee_phone: '',
  family_timezone: 5,
  // caller_phone: '',
  // caller_name: '',
  preferred_time: '16:00',
  ig_caller: ['', '', '']
};

// Use the initial state object for the ref
const signupData = ref({ ...initialSignupState });

const authUrl = ref('');
const loading = ref(false);

const route = useRoute();
const igAuthStatus = ref(route.query.ig_auth_status || null);

const router = useRouter();
const showModal = ref(false);
const timezone = ref('America/New_York');

// Add new ref for synced callers
const syncedCallers = ref({
  created: [],
});

// Update the watch effect to handle both success and failed states
watch(igAuthStatus, (newStatus) => {
  if (newStatus === 'success') {
    signupData.value.ig_verified = true;
    signupData.value.ig_username = route.query.ig_username || '';
    signupData.value.family_slug = route.query.ig_username || '';
  } else if (newStatus === 'failed') {
    signupData.value.ig_verified = false;
    signupData.value.ig_username = '';
  }
});

const getAuthUrl = async () => {
  try {
    const response = await axios.get(`${BACK_URL.value}/ig/instagram/auth_url`, {
      params: { state: 'ig_signup' }
    });
    authUrl.value = response.data.auth_url;
  } catch (error) {
    console.error('Error fetching Instagram auth URL:', error);
  }
};

const handleAllowConnection = async () => {
  loading.value = true;
  await getAuthUrl();

  if (authUrl.value) {
    // Save current signup data before redirecting
    localStorage.setItem('signupData', JSON.stringify(signupData.value));
    window.location.href = authUrl.value;
  }
  loading.value = false;
};

// Also update the onMounted check
onMounted(async () => {
  // Check if all required cookies are present
  const requiredCookies = [
    // 'caller_phone_number',
    // 'caller_name',
    // 'caller_id',
    'callee_name',
    'callee_phone_number',
    'callee_id',
    'family_id',
    'family_slug'
  ];

  const allCookiesPresent = requiredCookies.every(cookieName => {
    return document.cookie.split('; ').some(cookie => cookie.startsWith(`${cookieName}=`));
  });

  if (allCookiesPresent) {
    console.log('All required cookies are present. Redirecting to home.');
    router.push('/');
    return;
  }

  const storedData = localStorage.getItem('signupData');
  if (storedData) {
    signupData.value = JSON.parse(storedData);
  }
  if (route.query.ig_auth_status === 'success') {
    signupData.value.ig_verified = true;
    signupData.value.ig_username = route.query.ig_username || '';
    signupData.value.family_slug = route.query.ig_username || '';
  } else if (route.query.ig_auth_status === 'failed') {
    signupData.value.ig_verified = false;
    signupData.value.ig_username = '';
  }
  await getAuthUrl();
});

// Save data when it changes
watch(signupData, (newData) => {
  localStorage.setItem('signupData', JSON.stringify(newData));
}, { deep: true });

const validatePhoneNumber = (phone) => {
  // Validate format +1XXXXXXXXXX (exactly 12 characters)
  const phoneRegex = /^\+1\d{10}$/;
  return phoneRegex.test(phone);
};

const isLoading = ref(false);

const showSuccess = ref(false);

const handleLoginToStoryCall = async () => {
  // Format the phone number just before validation
  const formattedPhone = formatPhoneNumber(signupData.value.callee_phone);

  if (!validatePhoneNumber(formattedPhone)) {
    alert(ui_string.value.phone_format_error);
    return;
  }

  // Update the phone number for submission
  signupData.value.callee_phone = formattedPhone;

  if (!signupData.value.ig_verified) {
    alert('Please complete Instagram connection first.');
    return;
  }

  // Check if callee's Instagram username is available
  const calleeUsernameAvailable = await checkIgUsername(signupData.value.family_slug);
  if (!calleeUsernameAvailable) return;

  // Check if caller's Instagram username is available
  for (const igUsername of signupData.value.ig_caller) {
    if (igUsername && igUsername.trim()) {
      const callerUsernameAvailable = await checkIgUsername(igUsername);
      if (!callerUsernameAvailable) return;
    }
  }

  isLoading.value = true;
  try {
    // Create family with whitelist
    const familyResponse = await axios.post(`${BACK_URL.value}/family/family_create`, {
      name: signupData.value.family_slug.toLowerCase(),
      family_slug: signupData.value.family_slug.toLowerCase(),
      password: signupData.value.family_slug.toLowerCase(),
      family_instruct: '',
    });

    const familyId = familyResponse.data.id;

    // const callerIds = [];

    // // Create additional callers for each ig_caller
    // for (const igUsername of signupData.value.ig_caller) {
    //   if (igUsername && igUsername.trim()) {
    //     const callerResponse = await axios.post(`${BACK_URL.value}/caller/caller_create`, {
    //       name: igUsername.toLowerCase(),
    //       phone_number: '+18335761437',
    //       family_id: familyId,
    //       el_voice_id: "9RpXYdocFG8u7K3pqNxi",
    //       caller_instruct: '',
    //       ig_username: igUsername.toLowerCase(),
    //       default_caller: true
    //     });

    //     callerIds.push(callerResponse.data.id); // Store the caller ID

    //     // Set the first caller's ID and name in signupData
    //     if (!signupData.value.caller_id) {
    //       signupData.value.caller_id = callerResponse.data.id;
    //       signupData.value.caller_name = igUsername;
    //       signupData.value.caller_phone = '+18335761437';
    //     }
    //   }
    // }

    // Create callee using the first caller's ID from signupData
    const calleeResponse = await axios.post(`${BACK_URL.value}/callee/callee_create`, {
      callee_name: signupData.value.family_slug.toLowerCase(),
      phone_number: signupData.value.callee_phone,
      family_id: familyId,
      caller_id: signupData.value.caller_id,
      background_text: '',
      callee_instruct: '',
      ig_username: signupData.value.family_slug.toLowerCase()
    });

    // // Create schedule for each caller
    // for (const callerId of callerIds) {
    //   await axios.post(`${BACK_URL.value}/schedule/schedule_slot_schedule_update`, {
    //     callee_id: calleeResponse.data.id,
    //     caller_id: callerId, // Use the specific caller ID
    //     time: signupData.value.preferred_time,
    //     sun: true,
    //     mon: true,
    //     tue: true,
    //     wed: true,
    //     thu: true,
    //     fri: true,
    //     sat: true
    //   });
    // }

    // Update family settings (now directly in Family table)
    await axios.put(`${BACK_URL.value}/family/family_update/${familyId}`, {
      timezone: signupData.value.family_timezone,
      family_instruct: '',
    });

    // Set cookies for automatic login
    const expires = "expires=Fri, 31 Dec 9999 23:59:59 GMT";
    document.cookie = `family_slug=${signupData.value.family_slug};path=/;domain=.storycall.org;${expires}`;
    document.cookie = `family_id=${familyId};path=/;domain=.storycall.org;${expires}`;
    // document.cookie = `caller_id=${signupData.value.caller_id};path=/;domain=.storycall.org;${expires}`;
    // document.cookie = `caller_name=${signupData.value.caller_name};path=/;domain=.storycall.org;${expires}`;
    // document.cookie = `caller_phone_number=${signupData.value.caller_phone};path=/;domain=.storycall.org;${expires}`;
    document.cookie = `callee_id=${calleeResponse.data.id};path=/;domain=.storycall.org;${expires}`;
    document.cookie = `callee_name=${signupData.value.family_slug};path=/;domain=.storycall.org;${expires}`;
    document.cookie = `callee_phone_number=${signupData.value.callee_phone};path=/;domain=.storycall.org;${expires}`;

    // Create initial Instagram post
    try {
      await axios.post(`${BACK_URL.value}/ig/ig_intro`, {
        callee_id: calleeResponse.data.id,
        // ig_callers: signupData.value.ig_caller.filter(caller => caller.trim())
      });
    } catch (igError) {
      console.error('Failed to create initial Instagram post:', igError);
    }

    // Update the Instagram history sync call to store the returned caller lists
    try {
      const syncResponse = await axios.post(
        `${BACK_URL.value}/ig/sync_instagram_history/${signupData.value.family_slug}`
      );
      syncedCallers.value = {
        created: syncResponse.data.created_callers || [],
      };
    } catch (syncError) {
      console.error('Failed to sync Instagram history:', syncError);
    }

    showSuccess.value = true;
    localStorage.removeItem('signupData');
  } catch (error) {
    console.error('Failed to complete signup:', error);
    alert('Failed to complete signup. Please try again.');
  } finally {
    isLoading.value = false;
  }
};

const handleSuccessDone = () => {
  window.location.href = '/';
};

const fillTestData = () => {
  signupData.value = {
    ...initialSignupState,
    family_slug: 'granny555111',
    family_password: 'aiforgood',
    callee_phone: '2125187855',
    preferred_time: '18:00',
    family_timezone: 5, // Central Time
    // caller_name: 'kid-212',
    // caller_phone: '212-518-7855',
    ig_white_list: ['wh1', 'wh2', ''],
    ig_verified: true,
    caller_verified: true,
    ig_username: 'granny555111',
    ig_caller: ['admin555111x'],
  };
};

const clearData = () => {
  localStorage.removeItem('signupData');
  signupData.value = { ...initialSignupState };
};
const toggleSuccess = () => {
  showSuccess.value = !showSuccess.value;
};

const formatPhoneNumber = (phone) => {
  const numbers = phone.replace(/\D/g, '');
  const cleanNumbers = numbers.startsWith('1') ? numbers.slice(1) : numbers;
  return '+1' + cleanNumbers.slice(0, 10);
};

const copyMessageToClipboard = (message_text) => {
  navigator.clipboard.writeText(message_text).then(() => {
    alert('Message copied to clipboard!');
  }).catch(err => {
    console.error('Failed to copy message: ', err);
  });
};
</script>

<style scoped>
@import '../assets/ig_signup.css';

.ig-auth-button {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
}

.signup-title {
  font-size: 16px;
}

.status-icon {
  font-size: 1.5em;
}

.status-icon.success {
  color: #4CAF50;
}

.status-icon.failed {
  color: #f44336;
}

.info,
.error {
  padding: 15px;
  margin: 10px 0;
  border: none;
  background-color: transparent;
  font-size: 18px;
  font-weight: bold;
  border-radius: 0;
  color: #3b3b3b;
}

.error {
  border: 1px solid red;
}

.verification-code {
  display: block;
  font-size: 1.5em;
  margin-top: 10px;
  font-family: 'Courier New', Courier, monospace;
  text-align: center;
}

.connected-account {
  margin: 15px 0;
  color: #3b3b3b;
  border-radius: 4px;
  font-size: 16px;
}

.logo {
  max-width: 200px;
  margin: 0 auto 20px;
  display: block;
  max-height: 100px;
  border-radius: 50%;
}

.login-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.login-button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.fa-spinner {
  margin-right: 8px;
}

.success-message {
  text-align: center;
  margin: 20px 0;
}

.success-message p {
  white-space: pre-line;
  margin-bottom: 20px;
}

.notice {
  margin-top: 20px;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.copy-message {
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
}

.copy-message:hover {
  color: #0056b3;
}

.synced-callers {
  margin: 15px 0;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.caller-list {
  font-weight: bold;
  color: #2c3e50;
}
</style>
