<template>
  <Modal />
  <div class="grid-table-container">
    <div class="grid-table">
      <TopBar />
      <NavBar />
      <div class="nav-bar bottom-margin"></div>
      <div>
        <template v-if="caller_all || callee_all">
          {{ settings.formatUIString(ui_string.question_list_title_everyone, {
            caller_name: caller_all ? 'Everyone' : caller_name,
            callee_name: callee_all ? 'Everyone' : callee_name
          }) }}
        </template>
      </div>
      <template v-if="!caller_all && !callee_all && !allFixedSchedules" class="grid-table-cell-wide column">
        {{ settings.formatUIString(ui_string.question_list_slot_title, { callee_name: callee_name }) }}
        <div class="time-and-days-selector">
          <div class="time-pickers">
            <input type="time" v-model="selectedTime" class="time-selector" @change="updateSchedule" ref="timePicker" />
            <span class="time-separator">to</span>
            <input type="time" v-model="callWindowEnd" class="time-selector" @change="updateSchedule"
              ref="callWindowEndPicker" />
          </div>
          <div class="days-selector">
            <div v-for="day in days" :key="day.name" class="day-button" :class="{ 'selected': day.selected }"
              @click="day.selected = !day.selected; updateSchedule()">
              {{ day.name.charAt(0).toUpperCase() }}
            </div>
          </div>
        </div>
      </template>
      <draggable v-model="sortedQuestions" item-key="id" @end="onDragEnd" :animation="animationDuration"
        ghost-class="ghost-class" class="draggable-container" :delay="dragDelay" :move="checkMove"
        :disabled="caller_all || callee_all">
        <template #item="{ element }">
          <div class="grid-table-cell-wide row inlayed inlayed-top row-no-buttons" :class="{
            'fixed-schedule-row': element.schedule_type === 'fixed',
            'slot-schedule-row': element.schedule_type === 'slot'
          }">
            <div class="question-content">
              <i class="fas fa-grip-vertical"
                :style="{ visibility: (element.schedule_type === 'fixed' || caller_all || callee_all) ? 'hidden' : 'visible' }"></i>

              <div class="question-subject" @click="navToEditQuestion(element.id)"
                :class="{ 'past-fixed': isPastFixed(element) }">
                <template v-if="element.is_ig_reply_generated">
                  <span class="indicator">P</span>
                </template>
                <template v-if="element.is_ig_dm_generated">
                  <span class="indicator">DM</span>
                </template>
                {{ truncatedSubject(element.caller_name, element.callee_name, element.question_subject, caller_all,
                callee_all) }}
                <div v-if="element.schedule_call_time" class="scheduled-date"
                  :class="{ 'past-fixed': isPastFixed(element) }">
                  <span>{{ formatDate(element.schedule_call_time).dateString }}</span>
                  <span v-if="element.call_attempts > 0">,
                    {{ element.call_attempts === 1 ? '1 try' : `${element.call_attempts} tries` }}
                  </span>
                  &nbsp; <font-awesome-icon v-if="element.repeat_datetime" :icon="['fas', 'rotate-right']" />
                </div>
              </div>

              <div class="question-details">
                <div class="question-mapping-container">
                  <span v-if="!caller_all && !callee_all" class="delete-question"
                    @click.stop="deleteQuestion(element.id)">
                    <i class="fas fa-times"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </draggable>

      <template v-if="sortedQuestions.length > 0">
        <div class="drop-shadow"></div>
      </template>
      <template v-if="!caller_all && !callee_all">
        <div>{{ settings.formatUIString(ui_string.question_new_title, { callee_name }) }}</div>
        <div class="grid-table-cell-wide column top-margin">
          <textarea v-model="newNudge" :placeholder="ui_string.question_new_nudge_default" class="nudge-input"
            rows="5"></textarea>
        </div>

        <Instructs />

        <div class="grid-table-cell-narrow column">
          <button @click="createMessages" class="create-messages-button top-margin">{{
            ui_string.question_new_generate_button }}</button>
        </div>
        <div class="grid-table-cell-narrow column">
          <button @click="navToNewQuestion" class="">{{ ui_string.question_new_manual_button }}</button>
        </div>
        <!-- <div class="grid-table-cell-narrow ">
          <button @click="schedulesClear" class="schedule-button">{{ ui_string.question_new_slot_clear_button
            }}</button>
        </div> -->
      </template>
    </div>
  </div>
</template>
<script setup>

import { onMounted, ref, computed, watch } from "vue";
import { useRouter } from "vue-router";
import { useSettingsStore } from "../store";
import { storeToRefs } from 'pinia';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import draggable from 'vuedraggable';
import axios from "axios";
import NavBar from "./NavBar.vue";
import TopBar from "./TopBar.vue";
import Modal from "./Modal.vue";
import Instructs from './Instructs.vue';
import AudioRecorder from './AudioRecorder.vue';

const router = useRouter();
const settings = useSettingsStore();
const { showModal, timezoneMap, caller_phone_number, caller_id, BACK_URL, timezone, callee_id, callee_name, caller_name, callee_phone_number, family_instruct, caller_instruct, questions, callee_instruct, caller_all, callee_all, ui_string } = storeToRefs(settings);
const questions_mappings = ref([]);
const currentTime = new Date();
const currentTimePlusFiveMinutes = new Date(Math.ceil(currentTime.getTime() / 300000) * 300000);
const animationDuration = ref(300);
const dragDelay = ref(100);
const subjectTruncateLength = ref(25);
const selectedTime = ref('');
const callWindowEnd = ref('');
const newNudge = ref('');
const generatedMessages = ref([]);
const qaList = ref([]);
const timePicker = ref(null);
const callWindowEndPicker = ref(null);
const allFixedSchedules = ref(false);

const isAllFixedSchedules = () => {
  // Return false if there are no questions
  if (!questions.value?.length) {
    allFixedSchedules.value = false;
    return;
  }
  const allFixed = questions.value.every(question => question.schedule_type === 'fixed');
  allFixedSchedules.value = allFixed;
};

watch(questions, () => {
  isAllFixedSchedules();
});

watch(callee_id, async (newCalleeId) => {
  if (newCalleeId) {
    console
    await question_parties_map_get_all();
    await updateSelectedTime();
    await fetchQAList();
    await scheduleSlotScheduleGet();
    await settings.question_get_all();
  }
});

const createMessages = async () => {
  showModal.value = true;
  try {
    const response = await axios.post(`${BACK_URL.value}/ai/question_generate`, {
      callee_id: callee_id.value,
      family_instruct: family_instruct.value,
      callee_name: callee_name.value,
      caller_name: caller_name.value,
      callee_instruct: callee_instruct.value,
      caller_instruct: caller_instruct.value,
      callee_qa_list: JSON.stringify(qaList.value),
      question_nudge: newNudge.value,
      caller_id: caller_id.value,
      timezone: timezone.value
    });

    // Directly access the question_text array from the response data
    console.log(response)
    generatedMessages.value = response.data.question_text.slice(0, 5);

    // await settings.fetchSettings();
    await question_parties_map_get_all();

    // updateSelectedTime();
    await settings.question_get_all();
    await checkNotScheduledAndUpdateSchedule(questions.value);
    // await fetchQAList();
    // window.location.reload();

    console.log('Generated Messages Response:');
    console.log('===== System Prompt =====');
    console.log(response.data.settings_system_prompt);
    console.log('\n===== User Prompt =====');
    console.log(response.data.settings_user_prompt);
    console.log('\n===== Generated Questions =====');
    console.log(JSON.stringify(response.data.question_text, null, 2));
  } catch (error) {
    console.error('Failed to create messages:', error);
  } finally {
    showModal.value = false;
  }
};
const fetchQAList = async () => {
  try {
    const payload = {
      callee_id: callee_id.value,
      caller_id: caller_id.value
    };
    const response = await axios.post(`${BACK_URL.value}/answer/qalist_get_bycalleeid`, payload, {
      withCredentials: true
    });
    qaList.value = response.data;
  } catch (error) {
    console.error('Failed to fetch QA list:', error);
  }
};

const updateSelectedTime = async () => {
  if (!selectedTime.value) {
    selectedTime.value = currentTimePlusFiveMinutes.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZone: timezoneMap.value[timezone.value] || "UTC"
    });
  }
};



const formatDate = (callTime) => {
  const date = new Date(callTime);
  const timeZone = timezoneMap.value[timezone.value] || "UTC";

  const dayOfMonth = date.getDate();
  const dateString = date.toLocaleDateString("en-US", {
    timeZone: timeZone,
    weekday: "short",
    // month: "short"
  }).split(',')[0];

  const timeString = date.toLocaleString("en-US", {
    timeZone: timeZone,
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  }).replace(" AM", "a").replace(" PM", "p").toLowerCase();

  return { dateString: `${dateString} ${dayOfMonth}${getOrdinal(dayOfMonth)} ${timeString}` };
};

const getOrdinal = (n) => {
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return (v > 10 && v < 20) ? 'th' : (s[n % 10] || 'th');
};



const question_parties_map_get_all = async (id) => {
  showModal.value = true;
  try {
    const response = await axios.get(`${BACK_URL.value}/question/question_parties_map_get_all`);
    questions_mappings.value = response.data;
  } catch (error) {
    if (error.response) {
      console.error(`HTTP error! status: ${error.response.status}`);
    } else {
      console.error(`An error occurred: ${error}`);
    }
  } finally {
    showModal.value = false;
  }
};

const days = ref([
  { name: 'sun', selected: false },
  { name: 'mon', selected: false },
  { name: 'tue', selected: false },
  { name: 'wed', selected: false },
  { name: 'thu', selected: false },
  { name: 'fri', selected: false },
  { name: 'sat', selected: false },
]);

const scheduleSlotScheduleGet = async () => {
  const calleeId = callee_id.value;
  if (!calleeId) {
    console.error('callee_id is not set, skipping fetch');
    return;
  }

  showModal.value = true;
  try {
    const response = await axios.post(`${BACK_URL.value}/schedule/schedule_slot_schedules_bycalleeid`, {
      callee_id: calleeId,
      caller_id: caller_id.value
    });
    if (response.data.length > 0) {
      const schedule = response.data[0];
      selectedTime.value = schedule.time || '';
      callWindowEnd.value = schedule.end_time || '';
      days.value.forEach(day => {
        const dayShortName = day.name;
        if (schedule.hasOwnProperty(dayShortName) && schedule[dayShortName] !== undefined) {
          day.selected = schedule[dayShortName];
        }
      });
    }
  } catch (error) {
    console.error(`Failed to fetch schedule: ${error}`);
  } finally {
    showModal.value = false;
  }
};

const updateSchedule = async () => {
  try {
    // Update the slot schedule first
    const scheduleData = {
      time: selectedTime.value,
      end_time: callWindowEnd.value,
      callee_id: callee_id.value,
      caller_id: caller_id.value,
      days: days.value.reduce((acc, day) => {
        acc[day.name.toLowerCase()] = day.selected;
        return acc;
      }, {})
    };

    await axios.post(
      `${BACK_URL.value}/schedule/update_slot_schedule`,
      scheduleData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    // Get all questions in their current order to update their schedules
    const questions = sortedQuestions.value.map((q, index) => ({
      question_id: q.id,
      schedule_type: q.schedule_type || 'slot',
      position: index,
      caller_id: q.caller_id,
      callee_id: q.callee_id
    }));

    // Update all question schedules with new slot times
    await axios.post(
      `${BACK_URL.value}/schedule/update_schedules`,
      questions,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    // Refresh the questions list to show updated times
    await settings.question_get_all();
  } catch (error) {
    console.error('Failed to update schedule:', error);
  }
};

const deleteQuestion = async (id) => {
  try {
    await axios.delete(`${BACK_URL.value}/question/question_delete/${id}`);
    questions.value = questions.value.filter(q => q.id !== id);
    await updateSchedule();

  } catch (error) {
    if (error.response) {
      console.error(`HTTP error! status: ${error.response.status}`);
    } else {
      console.error(`An error occurred: ${error}`);
    }
  }
};
const navToEditQuestion = (id) => {
  router.push(`/question_edit/${id}`);
};

const navToNewQuestion = () => {
  router.push('/question_edit');
};


watch(callee_id, async (newCalleeId) => {
  if (newCalleeId) {
    await question_parties_map_get_all();
    await updateSelectedTime();
    await fetchQAList();
  }
});

const checkNotScheduledAndUpdateSchedule = async (newQuestions) => {
  const nonFixedQuestions = newQuestions.filter(question => question.schedule_type !== 'fixed');
  const nonFixedQuestionsWithoutCallTime = nonFixedQuestions.filter(question => !question.schedule_call_time);

  if (nonFixedQuestionsWithoutCallTime.length > 0) {
    await updateSchedule();
  }
};

const sortedQuestions = ref([]);

watch(questions, (newQuestions) => {
  // Sort questions by call_time
  sortedQuestions.value = [...newQuestions].sort((a, b) => {
    // Handle cases where schedule_call_time might be null
    if (!a.schedule_call_time) return 1;  // Push items without time to the end
    if (!b.schedule_call_time) return -1;

    // Convert strings to Date objects for comparison
    const timeA = new Date(a.schedule_call_time);
    const timeB = new Date(b.schedule_call_time);
    return timeA - timeB;
  });
}, { immediate: true, deep: true });

const onDragEnd = async (e) => {
  if (!e.from || !e.to || e.from !== e.to) return;

  // Get all questions in their new order
  const questions = sortedQuestions.value.map((q, index) => ({
    question_id: q.id,
    schedule_type: q.schedule_type || 'slot', // Default to slot if not specified
    position: index,
    caller_id: q.caller_id,
    callee_id: q.callee_id
  }));

  try {
    await axios.post(
      `${BACK_URL.value}/schedule/update_schedules`,
      questions,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    // Refresh the questions list to get updated call times
    await settings.question_get_all();
  } catch (error) {
    console.error('Failed to update schedule order:', error);
  }
};

watch([selectedTime, callWindowEnd], ([newStartTime, newEndTime]) => {
  if (newStartTime && newEndTime) {
    const startMinutes = parseInt(newStartTime.split(':')[0]) * 60 + parseInt(newStartTime.split(':')[1]);
    const endMinutes = parseInt(newEndTime.split(':')[0]) * 60 + parseInt(newEndTime.split(':')[1]);

    if (endMinutes <= startMinutes) {
      callWindowEnd.value = '';
      updateSchedule();
    }
  }
});

onMounted(async () => {
  await settings.fetchSettings();
  await question_parties_map_get_all();
  await updateSelectedTime();
  await fetchQAList();
  await scheduleSlotScheduleGet();
  isAllFixedSchedules();
  await settings.question_get_all();

  // Check for questions without a call time and update the schedule if necessary
  const nonFixedQuestionsWithoutCallTime = questions.value.filter(
    question => question.schedule_type !== 'fixed' && !question.schedule_call_time
  );
  if (nonFixedQuestionsWithoutCallTime.length > 0) {
    await updateSchedule();
  }

  window.scrollTo(0, 0);
});

const checkMove = (evt) => {
  // Don't allow dragging if in all mode or if it's a fixed question
  const question = evt.draggedContext.element;
  return !caller_all.value && !callee_all.value && question.schedule_type !== 'fixed';
};

const listQuestionsByCallDate = () => {
  sortedQuestions.value = [...questions.value].sort((a, b) => {
    const dateA = new Date(a.schedule_call_time);
    const dateB = new Date(b.schedule_call_time);

    // If one of the dates is invalid, move it to the end
    if (!a.schedule_call_time) return 1;
    if (!b.schedule_call_time) return -1;

    return dateA - dateB;
  });
};

const isPastFixed = (question) => {
  if (question.schedule_type === 'fixed' && !question.repeat_datetime) {
    const callTime = new Date(question.schedule_call_time);
    return callTime < new Date();
  }
  return false;
};

const truncatedSubject = (callerName, calleeName, subject, callerAll, calleeAll) => {
  let prefix = '';
  if (callerAll || calleeAll) {
    const callerText = callerName;
    const calleeText = calleeName;
    prefix = `${callerText} calls ${calleeText}: `;
  }

  const combinedText = prefix + subject;
  if (combinedText.length > subjectTruncateLength.value) {
    return combinedText.slice(0, subjectTruncateLength.value) + '...';
  }
  return combinedText;
};



</script>
<style scoped>
@import "../assets/question_list.css";
</style>
